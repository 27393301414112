<template>
  <Card>
    <div
      v-if="!loading && balances"
      :class="`grid grid-cols-1 ${isPayfacEnabled ? 'md:grid-cols-3' : 'md:grid-cols-4'} gap-4 w-full`"
    >
      <div :class="balanceClasses">
        <div class="w-full flex flex-col justify-center items-center h-full">
          <font-awesome-icon class="mb-2 text-8xl" :icon="['fad', 'money-bill-transfer']" />
          <span class="flex position-relative">
            <span class="mb-2 text-center"> Incoming </span>
            <Help class="ml-2 flex-col flex">
              <template #title> Incoming Funds </template>
              <p>Payments have been approved but the funds are not yet available for payouts.</p>
            </Help>
          </span>
          <h2>{{ $f.currency(balances.total_pending) }}</h2>
        </div>
      </div>
      <div :class="balanceClasses">
        <div class="w-full flex flex-col justify-center items-center h-full">
          <font-awesome-icon class="mb-2 text-8xl" :icon="['fad', 'wallet']" />
          <span class="flex position-relative">
            <span class="mb-2 text-center"> Bolster Wallet </span>
            <Help class="text-xs ml-2 flex-col flex">
              <template #title> What is my Bolster Wallet? </template>
              <p>
                Once your customer has made a payment and it has been processed the money will be
                displayed in this account. Your money will be automatically transferred to the bank
                account of your choice if you have auto payments turned on.
              </p>
            </Help>
          </span>
          <h2>{{ $f.currency(balances.total_balance) }}</h2>
        </div>
      </div>
      <div :class="balanceClasses">
        <div class="w-full flex flex-col justify-center items-center h-full">
          <font-awesome-icon class="mb-2 text-8xl" :icon="['fad', 'money-from-bracket']" />
          <span class="flex position-relative">
            <span class="mb-2 text-center">
              {{ isPayfacEnabled ? 'Sent to Bank' : 'Transferring' }}
            </span>
            <Help class="text-xs ml-2 flex-col flex">
              <template #title>
                What does {{ isPayfacEnabled ? 'Sent to Bank' : 'Transferring' }} mean?
              </template>
              <p>
                The funds from your wallet are now on there way to the bank account you setup to
                receive funds.
              </p>
            </Help>
          </span>
          <h2>{{ $f.currency(balances.total_in_transit) }}</h2>
        </div>
      </div>
      <div v-if="!isPayfacEnabled" :class="balanceClasses">
        <div class="w-full flex flex-col justify-center items-center h-full">
          <font-awesome-icon icon="university" size="3x" class="mb-2 flex justify-center" />
          <span class="flex position-relative">
            <span class="mb-2 text-center"> Deposited </span>
            <Help class="text-xs ml-2 flex-col flex">
              <template #title> What do you mean by deposited? </template>
              <p>The funds from the payment are now available in your bank account.</p>
            </Help>
          </span>
          <h2>{{ $f.currency(balances.total_paid * -1) }}</h2>
        </div>
      </div>
    </div>
    <div v-else class="grid grid-cols-3 gap-4">
      <Skeleton width="100%" height="8rem"></Skeleton>
      <Skeleton width="100%" height="8rem"></Skeleton>
      <Skeleton width="100%" height="8rem"></Skeleton>
    </div>
  </Card>
</template>

<script>
import PayFacMixin from '../../PayfacMixin'
import Skeleton from 'primevue/skeleton'

export default {
  name: 'CounterpartyBalances',
  props: {
    balances: {
      type: Object,
      default() {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * computed classes based on the amount of balance cards
     */
    balanceClasses() {
      return ['col-12 py-3 py-md-1 px-md-1', this.isPayfacEnabled ? 'col-md-4' : 'col-md-3']
    }
  },
  components: {
    Skeleton
  },
  mixins: [PayFacMixin]
}
</script>
