<template>
  <div class="payout-ctn p-4">
    <card-list>
      <card-list-field>
        <span> Source </span>
        <choose
          v-if="sourceAccounts"
          @input="(v) => setBalanceSource(v)"
          btnClass=""
          v-model="sourceAccountId"
          :staticSet="sourceAccounts"
        />
      </card-list-field>
      <card-list-field>
        <span> Send to </span>
        <choose
          v-if="filteredBankAccounts"
          @input="(v) => setAccount(v)"
          btnClass=""
          :disabled="!sourceAccountId"
          v-model="accountId"
          :staticSet="filteredBankAccounts"
        />
        <div v-else>
          <div class="loader flex items-center justify-center">
            <spinner class="info mr-4" :loading="1" size="2em" />
            <p>Loading accounts..</p>
          </div>
        </div>
      </card-list-field>
      <card-list-field>
        <span>
          Amount
          <small>
            balance available is ${{ $f.currency(sourceBalance?.total_balance) ?? 0 }}
          </small>
        </span>
        <field @input="checkMax" v-model="amount" format="currency" placeholder="$ 0.00" />
      </card-list-field>
      <div v-if="account && amount > 0">
        <card-list-field>
          <span>
            Payment Confirmation
            <small>
              Confirm payout of ${{ $f.currency(amount) }} USD from Bolster wallet to
              {{ account.name }}
            </small>
          </span>
          <toggle
            :value="paymentConfirmation"
            v-model="paymentConfirmation"
            :onValue="true"
            :offValue="false"
          />
        </card-list-field>
      </div>
    </card-list>

    <div class="text-right mt-4">
      <btn
        @click="confirmPayout"
        class="round mt-4 btn info"
        :disabled="!verified"
        :loading="creating"
      >
        Confirm
      </btn>
      <spinner v-if="creating" class="info mt-4 mx-auto" :loading="1" size="2em" />
    </div>
  </div>
</template>
<script>
import CounterpartyMixin from '../CounterpartyMixin'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    accounts: {
      type: Array,
      default: () => []
    },
    balances: {
      type: Array,
      default: () => []
    }
  },
  emits: ['successful', 'error'],
  data() {
    return {
      amount: 0,
      account: null,
      accountId: null,
      bankAccounts: [],
      sourceAccounts: [],
      sourceAccount: null,
      sourceBalance: null,
      sourceAccountId: null,
      paymentConfirmation: false,
      creating: false
    }
  },
  computed: {
    /**
     * Check to make sure they can make a payout
     */
    verified() {
      return (
        this.account &&
        this.amount &&
        this.accountId &&
        this.amount > 0 &&
        this.amount <= this.sourceBalance?.total_balance &&
        this.paymentConfirmation
      )
    },
    /**
     * Filtered bank accounts excluding the selected balance source
     */
    filteredBankAccounts() {
      if (!this.sourceAccountId) return []

      return this.bankAccounts.filter((account) => {
        return Number(account.value) !== Number(this.sourceAccountId)
      })
    }
  },
  methods: {
    /**
     * Check to see if the user has entered a value greater than the amount in their account
     * available for payout
     */
    checkMax() {
      if (this.amount > this.sourceBalance?.total_balance) {
        this.$store.dispatch('alert', {
          message: 'The amount you have chosen is more than the amount in your Bolster wallet.',
          error: true
        })
      }
    },
    /**
     * Set the selected bank account
     */
    setAccount(v) {
      if (v) {
        this.account = this.accounts.find((account) => account.id === Number(v))
        this.accountId = `${this.account.name ?? ''} (**** ${this.account.last4 ?? '****'})`
      } else {
        this.account = null
        this.accountId = null
      }
    },
    /**
     * Set the selected balance source
     */
    setBalanceSource(v) {
      this.accountId = null
      if (v) {
        this.sourceAccount = this.accounts.find((account) => account.id === Number(v))
        this.sourceBalance = this.balances.find(
          (balance) => balance.virtual_account_id === Number(v)
        )
        this.sourceAccountId = this.sourceBalance.virtual_account_id
      } else {
        this.sourceAccount = null
        this.sourceBalance = null
        this.sourceAccountId = null
      }
    },
    getSourceData() {
      const sourceExternalId = this.sourceAccount.external_id
      const sourceBalanceAccount = this.balances.find(
        (balance) => balance.virtual_account_id === this.sourceAccount.id
      )
      let isSourcePaymentInstrument = sourceExternalId.startsWith('PI')
      const sourceType = isSourcePaymentInstrument ? 'paymentInstrument' : 'balanceAccount'
      const sourceBalanceAccountId = sourceBalanceAccount.virtual_account_external_token

      return {
        sourceType,
        sourceExternalId,
        sourceBalanceAccountId
      }
    },
    getDestinationData() {
      const destinationExternalId = this.account.external_id
      const destinationBalanceAccount = this.balances.find(
        (balance) => balance.virtual_account_id === this.account.id
      )
      let isDestinationPaymentInstrument = destinationExternalId.startsWith('PI')
      const destinationType = isDestinationPaymentInstrument
        ? 'balanceAccount'
        : 'transferInstrument'
      const destinationBalanceAccountId = destinationBalanceAccount.virtual_account_external_token

      return {
        destinationType,
        destinationExternalId,
        destinationBalanceAccountId
      }
    },
    /**
     * Confirm and execute the manual payout
     */
    async confirmPayout() {
      this.creating = true

      const { sourceType, sourceExternalId, sourceBalanceAccountId } = this.getSourceData()
      const { destinationType, destinationExternalId, destinationBalanceAccountId } =
        this.getDestinationData()

      try {
        const payoutPayout = {
          amount: this.amount * 100,
          currency: this.$store.state.session.company.currency_iso,
          sourceType,
          sourceExternalId,
          sourceBalanceAccountId,
          destinationType,
          destinationExternalId,
          destinationBalanceAccountId
        }
        const { payload } = await this.createPayout(payoutPayout)
        if (payload.error) {
          throw new Error(payload.error.message)
        } else {
          this.$store.dispatch('alert', {
            message: 'Payout was successfully created.',
            error: false
          })
          this.$emit('successful')
        }
      } catch (e) {
        this.$store.dispatch('alert', {
          message: 'You have insufficient funds in your Adyen account for this transfer.',
          error: true
        })
        this.$emit('error', e)
      }
      this.creating = false
    }
  },
  mixins: [CounterpartyMixin],
  async mounted() {
    this.bankAccounts = this.accounts.map((a) => ({
      value: a.id,
      text: `${a.name} (**** ${a.last4})`
    }))
    this.sourceAccounts = this.bankAccounts.filter((ba) => {
      const account = this.balances.find((balance) => balance.virtual_account_id === ba.value)
      return account.virtual_account_type === this.type
    })

    const defaultBalance = this.balances.find(
      (balance) => balance.virtual_account_type === this.type
    )
    if (defaultBalance) {
      this.sourceAccount = this.accounts.find(
        (account) => account.id === defaultBalance.virtual_account_id
      )
      this.sourceBalance = defaultBalance
      this.sourceAccountId = defaultBalance.virtual_account_id
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss">
.payout-ctn {
  .field {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
  }
}
</style>
